import React from 'react'

function Footer(){
	return(
		<footer className="container">
			<hr></hr>
			<p className="text-center">&copy; postter 2024</p>
		</footer>
	);
}

export default Footer;